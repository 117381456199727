import { useState } from "react";
import { getFirestore, updateDoc, deleteDoc, doc } from "firebase/firestore";

const OrderModal = ({ orderData, closeModal }) => {
  const [selectedStatus, setSelectedStatus] = useState(orderData.status);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    updateStatusInFirestore(newStatus);
  };

  const handleDeleteOrder = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this order?"
    );
    if (confirmDelete) {
      try {
        const db = getFirestore();
        const orderRef = doc(
          db,
          "orders",
          orderData.orderYear,
          orderData.orderMonth,
          orderData.orderId
        );
        await deleteDoc(orderRef);
        console.log("Order deleted successfully");
        closeModal();
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    }
  };

  const updateStatusInFirestore = async (newStatus) => {
    const db = getFirestore();
    const orderRef = doc(
      db,
      "orders",
      orderData.orderYear,
      orderData.orderMonth,
      orderData.orderId
    );

    try {
      await updateDoc(orderRef, { status: newStatus });
      console.log("Status updated successfully in Firestore");
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }
  };

  const renderTableCell = (label, value) => {
    return (
      <tr>
        <td className="py-2 pr-4">{label}</td>
        <td className="py-2">{value || "NA"}</td>
      </tr>
    );
  };
  //  const orderDate = new Date(orderData.orderTimestamp)

  return (
    <div className="fixed inset-0 w-full flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
      <div className="bg-white rounded-lg p-8 max-w-screen-lg relative">
        <h2 className="text-2xl font-bold mb-6">Order Details</h2>
        <div className="overflow-y-auto max-h-96">
          <table className="w-full mb-6">
            <tbody>
              {renderTableCell("Name", orderData.name)}
              {renderTableCell("Phone Number", orderData.phoneNumber)}
              {renderTableCell("Alternate Number", orderData.alternateNumber)}
              {renderTableCell(
                "Address",
                `${orderData.apartment} ${orderData.address} ${orderData.city} ${orderData.postalCode} ${orderData.state} ${orderData.country}`
              )}
              {renderTableCell("User Email", orderData.userEmail)}
              {renderTableCell("Order ID", orderData.orderId)}
              {renderTableCell("Service Charge", orderData.serviceCharge)}
              {renderTableCell("Delivery Charge", orderData.deliveryCharge)}

              {renderTableCell(
                "Selected Payment Method",
                orderData.selectedPaymentMethod
              )}

              {/* Products Data */}
              {orderData.productsData.length > 0 && (
                <tr>
                  <th className="py-4 pr-4">Product Name</th>
                  <th className="py-4 pr-4" colSpan="2">
                    Product Image
                  </th>
                  <th className="py-4 pr-4">Quantity</th>
                  <th className="py-4 pr-4">Price</th>
                 
                </tr>
              )}
              {orderData.productsData.map((element, index) => (
                <tr key={index}>
                  <td className="py-2 pr-4">{element.productName}</td>
                  <td className="py-2 pr-4" colSpan="2">
                    {element.productImage ? (
                      <img
                        src={element.productImage}
                        alt="Product"
                        className="w-24 h-24 justify-center"
                      />
                    ) : (
                      "NA"
                    )}
                  </td>
                  <td className="py-2 pr-4">{element.productQuantity}</td>
                  <td className="py-2 pr-4">{element.productPrice}</td>
                  

                  
                </tr>
              ))}

              {/* Status */}
              <tr>
                <td className="py-2 pr-4">Status</td>
                <td className="py-2">
                  <select value={selectedStatus} onChange={handleStatusChange}>
                    <option value="pending">Pending</option>
                    <option value="orderConfirmed">Order Confirmed</option>
                    <option value="shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Returned">Returned</option>
                    <option value="Canceled">Canceled</option>
                    <option value="success">Done</option>
                  </select>
                </td>
              </tr>

              {/* Total Price */}
              <tr>
                <td className="py-2 pr-4">Total Price</td>
                <td className="py-2">{orderData.orderTotal || "NA"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-between">
          <button
            className="bg-red-500 mt-4 hover:bg-red-600 text-white px-6 py-3 border rounded"
            onClick={handleDeleteOrder}
          >
            Delete Order
          </button>
          <button
            className="bg-blue-500 mt-4 hover:bg-blue-600 text-white px-6 py-3 border rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
