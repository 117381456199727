import { useState } from "react"
import FilterSort from "./Components/FilterSort"
import GridComponent from "./Components/Grid"
import Header from "./Components/Header"
import { useParams } from "react-router-dom"


const ProductList = () => {
    const {list} = useParams();
    console.log(list)
    const [category, setCategory] = useState(list)
    const [filterCriteria, setFilterCriteria] = useState('');

    const handleFilter = (criteria) => {
      setFilterCriteria(criteria);
    };

    return (
        <>
             <Header back={true} />
      <GridComponent category={category} filterCriteria={filterCriteria} />
      <FilterSort onFilter={handleFilter} />


        </>
    )
}

export default ProductList