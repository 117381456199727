import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AddAddressOverlay from "./AddressOverlay";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase/firebaseConfig";
import { collection, query,updateDoc,getFirestore, getDocs, doc, setDoc, deleteDoc } from "firebase/firestore";


const AddAddress = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchUserAddresses = async () => {
        try {
          const addressesCollection = collection(getFirestore(), 'users', user.uid, 'addresses');
          const addressSnapshot = await getDocs(addressesCollection);
          const addressList = addressSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setAddresses(addressList);
        } catch (error) {
          console.error('Failed to fetch user addresses:', error);
        }
      };

      fetchUserAddresses();
    }
  }, [user]);

  const handleAddNewAddress = () => {
    setIsOverlayOpen(true);
  };

  const handleOverlayClose = () => {
    setIsOverlayOpen(false);
  };

  const handleSaveNewAddress = (newAddress) => {
    setAddresses([...addresses, newAddress]);
  };

  const handleEditAddress = (index) => {
    const selected = addresses[index];
    navigate(`/add-address/${selected.id}`);
  };

  const handleUpdateCartAddress = async () => {
    setIsProcessing(true);
  
    try {
      // Reference to the cart collection for the current user
      const cartCollectionRef = collection(getFirestore(), "users", user.uid, "cart");
  
      // Query to get the first document in the cart collection
      const cartQuery = query(cartCollectionRef);
      const querySnapshot = await getDocs(cartQuery);
  
      if (!querySnapshot.empty) {
        // Get the first document from the query snapshot
        const firstDoc = querySnapshot.docs[0];
        const cartDocRef = firstDoc.ref;
  


        // Get the selected address from the addresses array
        const addressToUpdate = addresses[selectedIndex];
  
        // Update the document with the entire address object
        await updateDoc(cartDocRef, {
          selectedAddress: addressToUpdate // Adjust the field name as needed
        });
  
        console.log('Cart updated with the selected address successfully');
        navigate('/cart');
      } else {
        console.error('No cart documents found!');
      }
    } catch (error) {
      console.error('Error updating cart:', error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSaveAddress = async (editedAddress) => {
    if (editedAddress.id) {
      await setDoc(doc(getFirestore(), 'users', user.uid, 'addresses', editedAddress.id), editedAddress);
      setAddresses(prevAddresses =>
        prevAddresses.map(address =>
          address.id === editedAddress.id ? editedAddress : address
        )
      );
    } else {
      const newAddressRef = doc(collection(getFirestore(), 'users', user.uid, 'addresses'));
      editedAddress.id = newAddressRef.id;
      await setDoc(newAddressRef, editedAddress);
      setAddresses(prevAddresses => [...prevAddresses, editedAddress]);
    }
    setSelectedAddress(null);
  };

  const handleSelectAddress = (id,index) => {
    setSelectedAddress(id);
    setSelectedIndex(index);
  };

  const handleDeleteAddress = async (index) => {
    const addressId = addresses[index].id;
    try {
      await deleteDoc(doc(getFirestore(), 'users', user.uid, 'addresses', addressId));
      setAddresses(prevAddresses => prevAddresses.filter((address, i) => i !== index));
      setSelectedAddress(null);
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  return (
    <>
      <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
          <div className="flex items-center md:w-auto w-full">
            <div className="flex items-center">
              <button onClick={() => navigate(-1)} className="focus:outline-none cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-black" viewBox="0 0 21 22">
                  <path fill="currentColor" d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"></path>
                </svg>
              </button>

              <Link to="/cart" className="ml-4 flex font-bold items-center">
                Select Address
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {isOverlayOpen && (
        <AddAddressOverlay
          onClose={handleOverlayClose}
          onSave={handleSaveNewAddress}
        />
      )}
      <div className="h-screen bg-white">
        <div className="p-4 bg-white">
          <button onClick={() => navigate("/add-address")} className="border text-sm font-bold w-full py-2 rounded-md border-black">
            Add new address
          </button>
        </div>

        <div className="px-4 mt-1 p-2 bg-gray-300 justify-center">
          Saved address
        </div>
        {addresses.map((item, index) => (
          <div
            key={item.id}
            onClick={() => handleSelectAddress(item.id,index)}
            className={`p-2 py-3 px-10 mt-4 rounded-md cursor-pointer`}
          >
            <div className="flex">
              {selectedAddress === item.id ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="addressBlocks-base-radioIcon">
                  <g fillRule="evenodd">
                    <path d="M8 14.933A6.941 6.941 0 0 1 1.067 8 6.941 6.941 0 0 1 8 1.067 6.941 6.941 0 0 1 14.933 8 6.941 6.941 0 0 1 8 14.933M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8"></path>
                    <path d="M8 3.429a4.571 4.571 0 1 0 0 9.143 4.571 4.571 0 0 0 0-9.143"></path>
                  </g>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className="addressBlocks-base-radioIcon">
                  <path fillRule="evenodd" d="M8 14.933A6.941 6.941 0 0 1 1.067 8 6.941 6.941 0 0 1 8 1.067 6.941 6.941 0 0 1 14.933 8 6.941 6.941 0 0 1 8 14.933M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8"></path>
                </svg>
              )}
              <p className="ml-5 font-bold text-sm">{item.name}</p>
            </div>

            <p className="ml-9 text-xs">{item.address}</p>
            <p className="ml-9 text-xs">{item.city}</p>
            <p className="ml-9 font-bold text-xs">Mobile - {item.mobileNumber}</p>

            {selectedAddress === item.id && (
              <div className="flex ml-9 mt-2 space-x-3">
                <button
                  className="text-sm p-2 px-3 border border-black rounded-md font-medium text-red-600 cursor-pointer"
                  onClick={() => handleDeleteAddress(index)}
                >
                  Remove
                </button>
                <button
                  className="text-sm p-2 px-3 border border-black rounded-md font-medium text-blue-600 cursor-pointer"
                  onClick={() => handleEditAddress(index)}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="z-40 border-t mt-3 bg-white py-1 bottom-0 sticky px-2 flex">
        <button
          className={`w-full bg-black p-2 text-white rounded justify-center ${
            selectedAddress === null ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => handleUpdateCartAddress()}
          disabled={selectedAddress === null}
        >
          {selectedAddress === null ? "Please Select Address" : (isProcessing ? "Processing..." : "Proceed To Book")}
        </button>
      </div>
    </>
  );
};

export default AddAddress;
