import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import AddProductModal from "./Components/AddProductsModel";
import AddCategory from "./Components/AddCategory";

import { initializeApp } from "firebase/app";
import { getDoc } from "firebase/firestore";
import { getFirestore, collection, query, onSnapshot, addDoc, deleteDoc, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import EditProductModal from "./Components/EditProductModel";
import axios from "axios";
import { uploadImageToAzure } from "./test";

const AdminProducts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [showAddModal, setshowAddModal] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [products, setProducts] = useState([]);
  const [showEditModal, setshowEditModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentProductKey, setCurrentProductKey] = useState('');

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'manuvini29@gmail.com') {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  const db = getFirestore();



  useEffect(() => {
    const getData = () =>{
      // axios.get('https://trishulsf.azurewebsites.net/')
      axios.get('http://localhost:8080/decorations/')
      .then((response) => {
        var productsArr  = [];
        var data = response.data
        data.forEach(element => {
          console.log(element);
         
          productsArr.push({ id: element.Id, category :element.Category__r.Name, price: element.Selling_Price__c, orignalPrice: element.Original_Price__c, name: element.Name, 
            description : JSON.parse(element.Decor_Desc__c),
            downloadUrls: [element.DecorationImages__r.records[0]]
          
          
           });
          console.log(productsArr)
        });

        setProducts(productsArr)
      })
    }
      return () => getData();

  }, [])

  const deleteProduct = (productId) => {
    const productRef = doc(db, "products", productId);
    deleteDoc(productRef)
      .then(() => {
        console.log("Product deleted successfully");
      })
      .catch((error) => {
        console.log("Error deleting product:", error.message);
      });
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleAddCategory = (category) => {
    setShowAddCategory(false);
  };

  const handleCancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = async (productId) => {
    console.log(products)
    console.log(productId)
    let  productData  = products.find(obj => obj.id == productId);
    console.log(productData);
       setCurrentProduct(productData);
        setCurrentProductKey(productId);
        setshowEditModal(true);
  };
  const handleDeleteClick = (productId) => {
    console.log(`Deleting product with ID ${productId}`);
  };


 


  const handleAddProduct = (productId) => {
    setshowAddModal(true);

  };

  
  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

 



  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <h2 className="text-2xl font-bold">Products</h2>
            {showAddModal&&(<AddProductModal showModal={showAddModal} 
            setShowModal={setshowAddModal}
            ></AddProductModal>)}
             {showEditModal&&(<EditProductModal showModal={showEditModal} 
            setShowModal={setshowEditModal} product={currentProduct} productId={currentProductKey}
            ></EditProductModal>)}
            <div>
              <button   onClick={() => handleAddProduct()} className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2">
                Add Product
              </button>
              <button onClick={() => setShowAddCategory(true)} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                Add Category
              </button>


            </div>
            {showAddCategory && (
                <AddCategory
                showModal={showAddCategory}
                setShowModal={setShowAddCategory}
                
                />
            )}
          </div>
          <p className="mb-4">Total Products: {products.length}</p>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">ID</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Name</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Price(₹)</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Category</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map((product) => (
                <tr key={product.id}>
                <td className="px-6 py-3 border">{product.id}</td>
                <td className="px-6 py-3 border">{product.name}</td>
                <td className="px-6 py-3 border">{product.price}</td>
                <td className="px-6 py-3 border">{product.category}</td>
                <td className="px-6 py-3 border w-1/6">
                  <button
                    className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handleEditClick(product.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                    onClick={() => deleteProduct(product.key)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
                ))}
              </tbody>
            </table>
            {totalPages > 1 && (
              <ul className="flex mt-4">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <li
                    key={index}
                    className={`px-3 py-1 border ${
                      currentPage === index + 1
                        ? "bg-gray-200"
                        : "hover:bg-gray-200"
                    }`}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default AdminProducts;
  
