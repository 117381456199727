import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimeChange = ({ currentDateTime, onDateTimeChange, isOpen, onClose }) => {
  const defaultDate = currentDateTime instanceof Date ? currentDateTime : new Date();
  const defaultTime = currentDateTime instanceof Date ? currentDateTime : new Date();

  const [newDate, setNewDate] = useState(defaultDate);
  const [newTime, setNewTime] = useState(defaultTime);

  const handleDateChange = (date) => {
    setNewDate(date);
  };

  const handleTimeChange = (time) => {
    
    setNewTime(time);
  };

  const formatTime = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const handleSubmit = () => {
    const newDateTime = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newTime.getHours(),
      newTime.getMinutes()
    );
    onDateTimeChange(String(newDateTime));
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed z-50 inset-0 bg-gray-800 opacity-50"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed w-96 z-50 bottom-0 transform transition-transform duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-full"
        } ease-in-out bg-white p-4`}
      >
        <div className="mb-4">
          <div className="py-3 font-semibold text-sm">Select New Date & Time</div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">Date</label>
            <DatePicker
              selected={newDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
              className="border border-gray-300 px-2 py-1 rounded w-full"
              />
          </div>

          <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Time</label>
            <DatePicker
              selected={newTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="border border-gray-300 px-2 py-1 rounded w-full"
              />
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="bg-green-400 text-white px-3 py-1 rounded cursor-pointer"
        >
          Change
        </button>

        {/* Display the updated date and time as a string */}
        {currentDateTime instanceof Date && (
          <div className="mt-2">
            Updated Date & Time: {currentDateTime.toLocaleString()}
          </div>
        )}
      </div>
    </>
  );
};

export default DateTimeChange;
