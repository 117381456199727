import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../firebase/firebaseConfig";
import { Link } from "react-router-dom";



const Order = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [user, loading] = useAuthState(auth);
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const firestore = getFirestore(); // Get Firestore instance

    useEffect(() => {
        // Fetch order details using orderId from Firestore
        const fetchOrderDetails = async () => {
            try {
                if (user) {
                    // Format date for Firestore
                    const date = new Date();
                    const year = date.getFullYear();
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const monthYear = `${year}${month}`; // e.g., '202408'

                    // Reference to the user's orders collection
                    const ordersCollectionRef = collection(firestore,'orders', monthYear, 'order');

                    // Query orders based on user ID
                    const q = query(ordersCollectionRef, where('userId', '==', user.uid));

                    // Get documents from Firestore
                    const querySnapshot = await getDocs(q);

                    // Process documents
                    const ordersData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    console.log('Orders:', ordersData);
                    setOrders(ordersData);
                }
            } catch (error) {
                console.error("Error fetching order details:", error.message);
            }
        };

        fetchOrderDetails();
    }, [user, firestore]);

    return (
        <>
            <nav className="sticky top-0 z-40 border-gray-600 bg-white shadow">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2">
                    <div className="flex items-center md:w-auto w-full">
                        <div className="flex items-center">
                            {true ? (
                                <button
                                    onClick={() => navigate(-1)}
                                    className="focus:outline-none cursor-pointer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 text-black"
                                        viewBox="0 0 21 22"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
                                        ></path>
                                    </svg>
                                </button>
                            ) : (
                                <button
                                    onClick={() => setIsMobileOpen(true)} // Handle mobile menu state
                                    className="relative group inline-flex items-center p-2 text-sm text-black rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-black hover:bg-gray-200 focus:ring-gray-200 ml-auto lg:ml-0 "
                                    aria-controls="mobile-menu-2"
                                    aria-expanded="false"
                                >
                                    <svg
                                        className="w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <svg
                                        className="hidden w-6 h-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        ></path>
                                    </svg>
                                </button>
                            )}
                        </div>

                        <Link to="/myOrders" className="ml-4 flex font-bold items-center">
                            My Orders
                        </Link>
                    </div>
                </div>
            </nav>

            <div className="border-t bg-gray-100 h-auto">
                <div className="flex mt-2 bg-white">
                    <div className="container mx-auto">
                        {orders.length > 0 ? (
                            orders.map(order => (
                                <div key={order.id} className="flex mt-3 bg-white border">
                                    <div className="w-44 h-40 p-2 overflow-hidden">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={order.imageUrl}
                                            alt="Order Image"
                                        />
                                        <button
                                            onClick={() => console.log("Remove button clicked")} // Implement your remove logic here
                                            className="absolute right-4 top-2"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" className="itemContainer-base-closeIcon">
                                                <path fill="#000" fillRule="evenodd" d="M9.031 8l6.756-6.756a.731.731 0 0 0 0-1.031.732.732 0 0 0-1.031 0L8 6.969 1.244.213a.732.732 0 0 0-1.031 0 .731.731 0 0 0 0 1.03L6.969 8 .213 14.756a.731.731 0 0 0 0 1.031.732.732 0 0 0 1.031 0L8 9.031l6.756 6.756a.732.732 0 0 0 1.031 0 .731.731 0 0 0 0-1.03L9.031 8z"></path>
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="text-xs mt-4">
                                        <p className="mt-2 font-semibold">{order.orderNumber}</p>
                                        <div className="flex mt-3">
                                            Order Total :
                                            <p className="font-bold"> ₹{order.total}</p>
                                        </div>

                                        <div className="flex mt-3">
                                            Payment Method :
                                            <p className="font-bold"> {order.PaymentMethod}</p>  
                                        </div>

                                        <div className="flex mt-3">
                                            Order Status : 
                                            <p className="font-bold"> {order.orderStatus}</p>
                                        </div>

                                        <div className="flex mt-2 space-x-1">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.63639 6.99013C6.84386 7.1976 6.84386 7.53397 6.63639 7.74143L5.7725 8.60533H8.27232C9.21251 8.60533 9.97949 7.84333 9.97949 6.89824C9.97949 5.95914 9.21859 5.19824 8.27949 5.19824H6.89116C6.59776 5.19824 6.35991 4.96039 6.35991 4.66699C6.35991 4.37359 6.59776 4.13574 6.89116 4.13574H8.27949C9.80539 4.13574 11.042 5.37234 11.042 6.89824C11.042 8.43232 9.79722 9.66783 8.27241 9.66783H5.77242L6.63639 10.5318C6.84386 10.7393 6.84386 11.0756 6.63639 11.2831C6.42893 11.4906 6.09256 11.4906 5.88509 11.2831L4.11426 9.51227C4.0417 9.43971 3.99452 9.35138 3.97271 9.25831C3.96352 9.21922 3.95866 9.17846 3.95866 9.13658C3.95866 9.05996 3.97488 8.98713 4.00407 8.92134C4.02519 8.87367 4.05366 8.82847 4.08949 8.78745C4.09828 8.77738 4.10745 8.76764 4.11697 8.75826L5.88509 6.99013C6.09256 6.78267 6.42893 6.78267 6.63639 6.99013Z" fill="#282C3F"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.416992 7.50033C0.416992 3.58831 3.58831 0.416992 7.50033 0.416992C11.4123 0.416992 14.5837 3.58831 14.5837 7.50033C14.5837 11.4123 11.4123 14.5837 7.50033 14.5837C3.58831 14.5837 0.416992 11.4123 0.416992 7.50033ZM7.50033 1.47949C4.17511 1.47949 1.47949 4.17511 1.47949 7.50033C1.47949 10.8255 4.17511 13.5212 7.50033 13.5212C10.8255 13.5212 13.5212 10.8255 13.5212 7.50033C13.5212 4.17511 10.8255 1.47949 7.50033 1.47949Z" fill="#282C3F"></path>
                                            </svg>
                                            <p>Free Cancellation Available</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No orders found.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Order;
