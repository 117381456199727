import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const GridComponent = ({ category }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track the current page of loaded products

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const db = getFirestore();
        const productsCollection = collection(db, 'products');
        let q;

        // Create a query to filter products by category
        if (category !== 'all') {
          q = query(productsCollection, where('category', '==', category));
        } else {
          q = productsCollection; // Get all products when category is "all"
        }

        // Execute the query and get the documents
        const querySnapshot = await getDocs(q);

        // Convert the documents to an array of product objects
        const productData = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            key: doc.id, // Use doc.id to uniquely identify each product
          };
        });

        // Format the product data as needed
        const formattedProducts = productData.map((p) => ({
          key: p.key,
          imageUrl: p.downloadUrls[0], // Assuming downloadUrls contains an array of image URLs
          name: p.name,
          originalPrice: Number(p.originalPrice),
          price: Number(p.price),
          info: p.description[0], // Assuming description is an array and you want the first element
        }));

        setProducts((prevProducts) => [...prevProducts, ...formattedProducts]);
        setLoading(false);
        setPage(page + 1);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]); // Reload products when category changes

  // const handleScroll = () => {
  //   const distanceFromBottom = document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);

  //   // Adjust the threshold as needed, for example, 100px from the bottom
  //   if (distanceFromBottom < 100) {
  //     setLoading(true);
  //     fetchProducts();
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []); // Add and remove event listener on component mount and unmount

  return (
    <div className="grid grid-cols-2">
      {products.map((item, index) => (
        <Link to={`/product/${item.key}`} key={index} className="bg-blue-300">
          <img
            src={item.imageUrl}
            alt={item.name}
            className="w-full h-auto border-r border-t border-white object-cover object-center"
            style={{ height: '255px' }}
          />
          <div className="py-2 pb-5 bg-white border-r border-gray-200">
            <p className="px-2 text-sm truncate font-semibold">{item.name}</p>
            <p className="px-2 text-xs truncate text-gray-400">{item.info}</p>
            <div className="px-2 flex">
              <p className="text-xs pr-1 font-semibold">
                ₹{item.price.toLocaleString()}
              </p>
              <p className="text-xs pr-1 text-gray-400 line-through">
                ₹{item.originalPrice.toLocaleString()}
              </p>
              <p className="text-xs text-orange-400 font-semibold">
                ({(((item.originalPrice - item.price) / item.originalPrice) * 100).toFixed(2)}%)
              </p>
            </div>
          </div>
        </Link>
      ))}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default GridComponent;
